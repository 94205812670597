import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isIOS } from "../../helpers/isIOS";
import { Page } from "../../components/Layout";
import useMedia from "use-media";
import styles from "./EditOrderStatus.module.scss";
import IcExpiredOrderM from "@/assets/SVGs/ic-expired-cancel-m.svg";
import IcExpiredOrderD from "@/assets/SVGs/ic-expired-cancel-d.svg";
import { ReactComponent as BackgroundDesktop } from "../../assets/SVGs/orderPlacedBackgroundDesktop.svg";
import { StringParam, useQueryParam } from "use-query-params";
import FixedBottom from "@/components/FixedBottom";
import DokumentasiUIButton from "@/components/DokumentasiUI/DokumentasiUIButton";

const EditOrderAlreadyExecution: React.FC = () => {
  const history = useHistory();
  const isMobile = useMedia({ maxWidth: "991.98px" });

  const [noOrderQueryParam] = useQueryParam("no_order", StringParam);

  useEffect(() => {
    if (noOrderQueryParam === undefined) {
      history.push("/");
    }
  }, [noOrderQueryParam]);

  return (
    <Page iOS={isIOS()} className={styles.page}>
      {isMobile ? null : (
        <div className={styles.backgroundDesktop}>
          <BackgroundDesktop />
        </div>
      )}
      <div className={styles.pageContainer}>
        <div className={styles.image}>
          {isMobile ? (
            <img src={IcExpiredOrderM} style={{ maxWidth: "190px", width: "120px" }}></img>
          ) : (
            <img src={IcExpiredOrderD}></img>
          )}
        </div>
        <span className={styles.dividerText}>...</span>
        <div className={styles.headingText} style={!isMobile ? { width: "360px" } : {}}>
          Maaf, order dengan nomor {noOrderQueryParam} tidak dapat diubah karena telah masuk ke
          dalam proses pengerjaan
        </div>
      </div>
      <p className="bluish-black-100 ktext-center kpx-2">
        Jika kamu membutuhkan bantuan, silakan <br />
        hubungi customer service kami melalui <br />
        Whatsapp
      </p>

      <FixedBottom>
        <DokumentasiUIButton noDocUI={isMobile ? "KN UI 1739" : "KN UI 1738"} />
      </FixedBottom>
    </Page>
  );
};

export default EditOrderAlreadyExecution;
