import useStore from "@/stores/useStore";
import { Link } from "react-router-dom";
import styles from "./DokumentasiUIButton.module.scss";
import classNames from "classnames";

export type DokumentasiUIButtonProps = {
  noDocUI?: string;
  className?: string;
  containerClassname?: string;
};

export default function DokumentasiUIButton({
  noDocUI,
  className,
  containerClassname,
}: DokumentasiUIButtonProps) {
  const showDokumentasi = useStore((state) => state.showDokumentasi);

  if (!showDokumentasi) return null;

  if (!noDocUI) return null;

  return (
    <div className={classNames(styles.containerLinkUI, containerClassname)}>
      <Link
        to={{
          pathname: `http://dokumentasi.co.id/knitto/ui/${encodeURIComponent(noDocUI)}`,
        }}
        target="_blank"
        className={classNames(styles.link, className)}
      >
        {noDocUI}
      </Link>
    </div>
  );
}
