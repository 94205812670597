import { ComponentProps } from "react";
import classNames from "classnames";
import styles from "./FixedBottom.module.scss";

export type FixedBottomProps = ComponentProps<"div">;

export default function FixedBottom({ children, className, ...props }: FixedBottomProps) {
  return (
    <div className={classNames(styles.container, className)} {...props}>
      {children}
    </div>
  );
}
